.day-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.day-select .day-select-option {
    cursor: pointer;
    margin: 0 5px;
    color: #1890ff;
}

.day-select .day-select-option:hover {
    color: #40a9ff;
}

.day-clear-btn {
    width: 25px;
    height: 25px;
    margin-top: 5px;
    float: right;
}

.ant-picker.no-text {
    width: 24px;
    font-size: 24px;
    padding: 0;
    border: none;
    background: none;
    color: inherit;
}

.ant-picker.no-text .ant-calendar-picker-clear,
.ant-picker.no-text input {
    display: none;
}

.ant-picker.no-text .ant-calendar-picker-icon::after {
    cursor: pointer;
}

.ant-picker.no-text .ant-picker-suffix {
    margin-right: 0;
    color: inherit;
}

.ant-picker.past .ant-calendar-picker-icon:after {
    color: red;
}

.ant-picker.today .ant-calendar-picker-icon:after {
    color: #008a00;
}
