.planned-item-name {
    color: grey;
}

.habit-list-name,
.planned-item-name {
    display: flex;
    gap: 5px;
    flex-flow: row nowrap;
    align-items: center;
}

.habit-list-name > span,
.planned-item-name > span {
    flex: 0 0 auto;
}

.habit-list-name > span.long-name,
.planned-item-name > span.long-name {
    flex: 1 1 auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.planned-item-name > a {
    flex: 0 0 14px;
    justify-content: right;
}

.planned-item-name i {
    color: grey;
    cursor: pointer;
}

.planned-item-name i:hover {
    color: red;
}

.planned-item-name i.anticon:before {
    display: inline-block;
}

.planned-item-name i,
.habit-list-name i,
.planned-item-name .ant-checkbox-wrapper {
    margin-right: 5px;
}

.planned-item-name.planned {
    color: red;
}

.planned-item-name.planned i.planned-icon {
    display: inherit;
}

.planned-item-name .ant-calendar-picker .ant-calendar-picker-icon::after {
    color: grey;
}

.planned-item-name .ant-calendar-picker .ant-calendar-picker-icon:hover::after {
    color: red;
}
