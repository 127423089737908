.selection-edit-panel {
    left: 0;
    bottom: -90px;
    width: 100%;
    height: 90px;
    background-color: white;
    position: absolute;
    transition: bottom 100ms;
    display: none;
    flex-flow: column nowrap;
    justify-content: space-between;
    border-bottom: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 15px 0 #888;
}

.selection-edit-panel.short-list {
    height: 90px;
    bottom: -90px;
}

.selection-edit-panel.full-list {
    height: 440px;
    bottom: -440px;
}

.selection-edit-panel.short-list.extended {
    height: 200px;
}

.selection-edit-panel.full-list.extended {
    height: 440px;
}

.selection-edit-panel.editing {
    bottom: 0;
}

.selection-edit-panel .drag-to-close {
    flex: 0 0 30px;
    padding: 10px 0 14px 0;
}
.selection-edit-panel .drag-to-close .inner {
    width: 100px;
    height: 6px;
    background-color: #34a853;
    border-radius: 3px;
    margin: 0 auto;
}

.selection-edit-panel .property-edit-buttons {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 fit-content;
    padding-bottom: 10px;
}

.selection-edit-panel .action-buttons {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.selection-edit-panel.extended .action-buttons .property-button {
    opacity: 0.3;
}

.selection-edit-panel.extended.DATE .action-buttons .property-button.DATE,
.selection-edit-panel.extended.IMPORTANCE .action-buttons .property-button.IMPORTANCE,
.selection-edit-panel.extended.URGENCY .action-buttons .property-button.URGENCY,
.selection-edit-panel.extended.PERIOD .action-buttons .property-button.PERIOD {
    opacity: 1;
}

.selection-edit-panel.short-list .action-buttons .property-button.less,
.selection-edit-panel.full-list .action-buttons .property-button.more {
    display: none;
}

.selection-edit-panel div.property-button,
.selection-edit-panel div.property-value-button {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 5px;
    touch-action: none;
}

.selection-edit-panel div.property-button > span.label,
.selection-edit-panel div.property-value-button > span.label {
    font-size: 10px;
    line-height: 10px;
}

.selection-edit-panel .values-panel {
    width: 100%;
    height: 100px;
    background-color: white;
    flex-flow: column nowrap;
    display: none;
    margin-bottom: 10px;
}

.selection-edit-panel .values-panel .info {
    font-size: 15px;
    padding: 0 0 10px 10px;
    font-weight: bold;
}

.selection-edit-panel.extended .values-panel {
    display: flex;
}

.selection-edit-panel.extended.full-list .values-panel {
    border-top: solid 1px lightgray;
}

.selection-edit-panel.extended.full-list .values-panel .info {
    padding-top: 10px;
}

.selection-edit-panel .more-edit-fields {
    flex: 1 1 auto;
    overflow: auto;
    padding: 0 10px;
    display: none;
}

.selection-edit-panel.full-list .more-edit-fields {
    display: initial;
}

.item-action-dnd-preview {
    z-index: 6;
}

.property-value-button.IMPORTANCE.MINOR button,
.property-value-button.URGENCY.MINOR button {
    color: white;
    background-color: #008a00;
}

.property-value-button.IMPORTANCE.NORMAL button,
.property-value-button.URGENCY.NORMAL button {
    color: white;
    background-color: #faad14;
}

.property-value-button.IMPORTANCE.HIGH button,
.property-value-button.URGENCY.HIGH button {
    color: white;
    background-color: red;
}

.delete-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 5px;
}

.delete-container button,
.delete-container button:focus {
    background-color: red;
    color: white;
}

.delete-container button span.anticon {
    padding-right: 10px;
}

@media only screen and (max-width: 450px) {
    .selection-edit-panel {
        display: flex;
    }
}
