.results-filter-header,
.results-filter-header .selection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.results-filter-header .selection {
    flex: 0 0 295px;
    justify-content: flex-start;
}

.results-filter-header .back-button {
    flex: 0 0 50;
    margin-right: 10px;
    display: none;
}

.results-filter-header .period-select {
    flex: 0 0 95px;
}

.results-filter-header .date-selection {
    margin-left: 5px;
    flex: 0 0 195px;
}

@media only screen and (max-width: 700px) {
    .results-filter-header .back-button {
        display: initial;
    }
}
