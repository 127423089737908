.add-item {
    height: 54px;
    line-height: 54px;
    font-size: 23px;
    background-color: #34a853;
    text-align: center;
}

.list-renderer.empty .add-item {
    border: 1px solid rgba(255, 255, 255, 0.65);
    margin-top: 16px;
}

.add-item i {
    padding: 0 12px;
}

.add-item .invite-mode{
    cursor: pointer;
    color: white;
}

.list-renderer.empty .add-item .invite-mode,
.add-item:hover .invite-mode{
    color: rgba(255, 255, 255, 0.65);
}
