.buttons-holder {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

button img {
    vertical-align: top;
}

.login-form .reset-password {
    float: right;
    margin-top: -20px;
    margin-bottom: 10px;
}
