.notes .page-list,
.notes .page-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.notes > div > .header,
.notes > div > .footer {
    width: 100%;
    overflow: hidden;
    flex: 0 0 50px;
}

.notes > div > .header {
    flex: 0 0 32px;
}

.notes > div > .footer {
    margin-top: 10px;
}

.notes > div > .content {
    width: 100%;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 8px;
}

.notes > .page-list .header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.notes > .page-list .header .search {
    flex: 1 1 auto;
}

.notes > .page-list .header .plus-button {
    flex: 0 0 50px;
    margin-left: 5px;
    display: none;
}

.notes > .page-list .notes-list {
    background-color: white;
}

.notes > .page-list .note-list-item {
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 12px;
    padding-right: 12px;
}

.notes > .page-list .note-list-item h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.notes > .page-list .note-list-item:hover {
    background-color: rgba(128, 214, 255, 0.2);
}

.notes > .page-list .note-list-item.selected {
    background-color: #80d6ff;
}

.notes > .page-list .note-list-item .actions {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
}

.notes > .page-content > .header {
    display: flex;
}

.notes > .page-content > .header > input,
.notes > .page-content > .header > button {
    margin-right: 10px;
}

.notes > .page-content > .header > .back-button {
    display: none;
    width: 50px;
}

.notes .content.countdown1 .ql-editor{opacity: 0.99;}
.notes .content.countdown2 .ql-editor{opacity: 0.98;}
.notes .content.countdown3 .ql-editor{opacity: 0.97;}
.notes .content.countdown4 .ql-editor{opacity: 0.96;}
.notes .content.countdown5 .ql-editor{opacity: 0.95;}
.notes .content.countdown6 .ql-editor{opacity: 0.94;}
.notes .content.countdown7 .ql-editor{opacity: 0.93;}
.notes .content.countdown8 .ql-editor{opacity: 0.92;}
.notes .content.countdown9 .ql-editor{opacity: 0.91;}
.notes .content.countdown10 .ql-editor{opacity: 0.90;}
.notes .content.countdown11 .ql-editor{opacity: 0.89;}
.notes .content.countdown12 .ql-editor{opacity: 0.88;}
.notes .content.countdown13 .ql-editor{opacity: 0.87;}
.notes .content.countdown14 .ql-editor{opacity: 0.86;}
.notes .content.countdown15 .ql-editor{opacity: 0.85;}
.notes .content.countdown16 .ql-editor{opacity: 0.84;}
.notes .content.countdown17 .ql-editor{opacity: 0.83;}
.notes .content.countdown18 .ql-editor{opacity: 0.82;}
.notes .content.countdown19 .ql-editor{opacity: 0.81;}
.notes .content.countdown20 .ql-editor{opacity: 0.80;}
.notes .content.countdown21 .ql-editor{opacity: 0.79;}
.notes .content.countdown22 .ql-editor{opacity: 0.78;}
.notes .content.countdown23 .ql-editor{opacity: 0.77;}
.notes .content.countdown24 .ql-editor{opacity: 0.76;}
.notes .content.countdown25 .ql-editor{opacity: 0.75;}
.notes .content.countdown26 .ql-editor{opacity: 0.74;}
.notes .content.countdown27 .ql-editor{opacity: 0.73;}
.notes .content.countdown28 .ql-editor{opacity: 0.72;}
.notes .content.countdown29 .ql-editor{opacity: 0.71;}
.notes .content.countdown30 .ql-editor{opacity: 0.70;}
.notes .content.countdown31 .ql-editor{opacity: 0.69;}
.notes .content.countdown32 .ql-editor{opacity: 0.68;}
.notes .content.countdown33 .ql-editor{opacity: 0.67;}
.notes .content.countdown34 .ql-editor{opacity: 0.66;}
.notes .content.countdown35 .ql-editor{opacity: 0.65;}
.notes .content.countdown36 .ql-editor{opacity: 0.64;}
.notes .content.countdown37 .ql-editor{opacity: 0.63;}
.notes .content.countdown38 .ql-editor{opacity: 0.62;}
.notes .content.countdown39 .ql-editor{opacity: 0.61;}
.notes .content.countdown40 .ql-editor{opacity: 0.60;}
.notes .content.countdown41 .ql-editor{opacity: 0.59;}
.notes .content.countdown42 .ql-editor{opacity: 0.58;}
.notes .content.countdown43 .ql-editor{opacity: 0.57;}
.notes .content.countdown44 .ql-editor{opacity: 0.56;}
.notes .content.countdown45 .ql-editor{opacity: 0.55;}
.notes .content.countdown46 .ql-editor{opacity: 0.54;}
.notes .content.countdown47 .ql-editor{opacity: 0.53;}
.notes .content.countdown48 .ql-editor{opacity: 0.52;}
.notes .content.countdown49 .ql-editor{opacity: 0.51;}
.notes .content.countdown50 .ql-editor{opacity: 0.50;}
.notes .content.countdown51 .ql-editor{opacity: 0.49;}
.notes .content.countdown52 .ql-editor{opacity: 0.48;}
.notes .content.countdown53 .ql-editor{opacity: 0.47;}
.notes .content.countdown54 .ql-editor{opacity: 0.46;}
.notes .content.countdown55 .ql-editor{opacity: 0.45;}
.notes .content.countdown56 .ql-editor{opacity: 0.44;}
.notes .content.countdown57 .ql-editor{opacity: 0.43;}
.notes .content.countdown58 .ql-editor{opacity: 0.42;}
.notes .content.countdown59 .ql-editor{opacity: 0.41;}
.notes .content.countdown60 .ql-editor{opacity: 0.40;}
.notes .content.countdown61 .ql-editor{opacity: 0.39;}
.notes .content.countdown62 .ql-editor{opacity: 0.38;}
.notes .content.countdown63 .ql-editor{opacity: 0.37;}
.notes .content.countdown64 .ql-editor{opacity: 0.36;}
.notes .content.countdown65 .ql-editor{opacity: 0.35;}
.notes .content.countdown66 .ql-editor{opacity: 0.34;}
.notes .content.countdown67 .ql-editor{opacity: 0.33;}
.notes .content.countdown68 .ql-editor{opacity: 0.32;}
.notes .content.countdown69 .ql-editor{opacity: 0.31;}
.notes .content.countdown70 .ql-editor{opacity: 0.30;}
.notes .content.countdown71 .ql-editor{opacity: 0.29;}
.notes .content.countdown72 .ql-editor{opacity: 0.28;}
.notes .content.countdown73 .ql-editor{opacity: 0.27;}
.notes .content.countdown74 .ql-editor{opacity: 0.26;}
.notes .content.countdown75 .ql-editor{opacity: 0.25;}
.notes .content.countdown76 .ql-editor{opacity: 0.24;}
.notes .content.countdown77 .ql-editor{opacity: 0.23;}
.notes .content.countdown78 .ql-editor{opacity: 0.22;}
.notes .content.countdown79 .ql-editor{opacity: 0.21;}
.notes .content.countdown80 .ql-editor{opacity: 0.20;}
.notes .content.countdown81 .ql-editor{opacity: 0.19;}
.notes .content.countdown82 .ql-editor{opacity: 0.18;}
.notes .content.countdown83 .ql-editor{opacity: 0.17;}
.notes .content.countdown84 .ql-editor{opacity: 0.16;}
.notes .content.countdown85 .ql-editor{opacity: 0.15;}
.notes .content.countdown86 .ql-editor{opacity: 0.14;}
.notes .content.countdown87 .ql-editor{opacity: 0.13;}
.notes .content.countdown88 .ql-editor{opacity: 0.12;}
.notes .content.countdown89 .ql-editor{opacity: 0.11;}
.notes .content.countdown90 .ql-editor{opacity: 0.10;}
.notes .content.countdown91 .ql-editor{opacity: 0.09;}
.notes .content.countdown92 .ql-editor{opacity: 0.08;}
.notes .content.countdown93 .ql-editor{opacity: 0.07;}
.notes .content.countdown94 .ql-editor{opacity: 0.06;}
.notes .content.countdown95 .ql-editor{opacity: 0.05;}
.notes .content.countdown96 .ql-editor{opacity: 0.04;}
.notes .content.countdown97 .ql-editor{opacity: 0.03;}
.notes .content.countdown98 .ql-editor{opacity: 0.02;}
.notes .content.countdown99 .ql-editor{opacity: 0.01;}

@media only screen and (max-width: 700px) {
    .notes.page > .page-list, .notes.page > .page-content {
        flex: 1 1 auto;
        margin-left: 0;
    }

    .notes.page.note-details > .page-list, .notes.page.notes-list > .page-content {
        display: none;
    }

    .notes > .page-content > .header > .back-button {
        display: block;
    }
}

@media only screen and (max-width: 450px) {
    .notes > .page-list .header .plus-button {
        display: initial;
    }

    .notes > .page-list .footer {
        display: none;
    }
}
