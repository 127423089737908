.priority-list-filter {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.priority-list-filter .icon {
    flex: 0 0 30px;
    vertical-align: center;
}

.priority-list-filter .period-select {
    flex: 1 1 auto;
}

.priority-list-filter .plus-button {
    margin-left: 5px;
    display: none;
}

@media only screen and (max-width: 450px) {
    .priority-list-filter .plus-button {
        display: initial;
    }
}
