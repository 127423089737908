tr.action-item-tr.selected > td {
    background: #80d6ff !important;
}

tr.action-item-tr.drop-over-downward > td,
tr.action-item-tr.drop-over-upward > td {
    background-color: darkgrey !important;
}

tr.action-item-tr > td {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 16px;
    min-height: 55px;
}

tr.action-item-tr > td > .ant-table-row-expand-icon,
tr.action-item-tr > td > .ant-table-row-indent {
    flex: 0 0 auto;
}
