.application {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    background-color: #f0f2f5;
}

a:focus,
button:focus,
input:focus,
textarea:focus ,
label:focus {
    outline: none;
}

.application > .application-header {
    width: 100%;
    flex: 0 0 50px;
    display: none;
    flex-flow: row nowrap;
    overflow: hidden;
    background-color: black;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: white;
    font-size: 25px;
}

.application-header .container {
    width: 90px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.application-header .container.left {
    justify-content: left;
}

.application-header .container.right {
    justify-content: right;
}

.application > .application-content {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    justify-content: space-between;
}

.app-menu-sider {
    flex: 0 0 150px;
    background-color: #34a853;
    color: white;
    overflow: hidden;
}

.app-menu-sider.small {
    flex: 0 0 60px;
}

.app-page-content {
    flex: 1 1 auto;
    overflow: hidden;
    padding: 10px;
}

.app-menu-sider .menu-item {
    font-size: 16px;
    white-space: nowrap;
    vertical-align: center;
    width: 100%;
    display: inline-block;
    color: white;
    height: 50px;
    cursor: pointer;
}

.application.goals .menu-item.goals,
.application.habits .menu-item.habits,
.application.results .menu-item.results,
.application.notes .menu-item.notes,
.application.profile .menu-item.profile,

.app-menu-sider .menu-item:hover {
    background-color: #f0f2f5;
    color: #34a853;
}

.app-menu-sider .menu-item div {
    display: inline-block;
    padding: 0 15px;
    font-size: 30px;
    width: 60px;
}

.app-menu-sider .menu-item span {
    vertical-align: text-bottom;
    font-weight: bold;
}

.app-menu-sider .menu-item.menu-size-change {
    font-size: 20px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 7px;
}

.app-menu-sider.large .menu-item.menu-size-change.large {
    display: none;
}

.app-menu-sider.small .menu-item.menu-size-change.small {
    display: none;
}

.page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
}

.page > .page-list {
    flex: 0 0 320px;
    height: 100%;
}

.page > .page-content {
    flex: 1 1 auto;
    margin-left: 10px;
    height: 100%;
    background-color: #f0f2f5;
    overflow: hidden;
}

.header-switch {
    width: 90px;
}

.header-switch .ant-radio-button-wrapper {
    padding: 0 11px;
    font-size: 22px;
    width: 45px;
}

.header-switch .ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-checked) {
    color: #34a853 !important;
}

.header-switch .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #34a853 !important;
    border-color: #34a853 !important;
}

.header-switch .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #34a853 !important;
}

.header-switch.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
}

@media only screen and (max-width: 700px) {
    .page {
        padding: 0;
    }
}

@media only screen and (max-width: 450px) {
    .application > .application-content {
        flex-flow: column-reverse nowrap;
    }

    .application > .application-content > .app-menu-sider {
        flex: 0 0 50px;
    }

    .application > .application-content > .app-menu-sider .ant-space.ant-space-vertical {
        flex-direction: row;
    }

    .application > .application-content > .app-menu-sider .menu-item {
        width: 60px;
        overflow: hidden;
    }

    .application > .application-content > .app-menu-sider .menu-item.menu-size-change {
        display: none;
    }

    .main-header {
        display: none;
    }

    .application > .application-header {
        display: flex;
    }
}
