.new-item-modal .ant-modal-header .ant-modal-title {
    width: calc(100% - 30px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.parent-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

.parent-container .properties-holder {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 30px;
}

.time-period-holder {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
}

.importance-urgency-holder {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
}

.tripple-selection {
    width: 105px;
}

.new-item-modal .ant-modal-body {
    padding: 10px 24px 0 24px;
}

@media only screen and (max-width: 450px) {
    .ant-modal.new-item-modal {
        top: 85px;
    }

    .new-item-modal .ant-modal-header {
        padding: 10px 10px;
    }

    .new-item-modal .ant-modal-body {
        padding: 10px 10px 0 10px;
    }

    .new-item-modal .ant-modal-footer {
        padding: 10px 10px;
    }

    .time-period-holder {
        flex-flow: column nowrap;
        gap: 5px;
    }

    .importance-urgency-holder {
        flex-flow: column nowrap;
        gap: 25px;
    }

    .ant-modal-close-x {
        width: 44px;
        height: 44px;
        line-height: 44px;
    }

    .new-item-modal .ant-modal-header .ant-modal-title {
        width: calc(100% - 34px);
    }

    .tripple-selection {
        width: 150px;
    }
}
