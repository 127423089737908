.goals-priority-list-container.ant-list .ant-list-item {
    cursor: pointer;
}

.goals-priority-list-container.ant-list .ant-list-item:hover {
    background-color: rgba(128, 214, 255, 0.2);
}

.goals-priority-list-container.ant-list .ant-list-item.selected {
    background-color: #80d6ff;
}

.goals-priority-list-container.ant-list .ant-list-item .ant-list-item-meta-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.goals-priority-list-container.ant-list .ant-list-item .ant-list-item-meta-description .actions {
    display: flex;
    justify-content: space-between;
}

.goals-priority-list-container.ant-list .ant-list-item .ant-list-item-meta-description .actions > div {
    display: flex;
    margin-right: 10px;
    gap: 3px;
}

.goals-priority-list-container.ant-list .ant-list-item .ant-list-item-meta-description .actions > div > div i {
    margin-right: 5px;
}

.duration-select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.duration-select > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.duration-select > div > label > span:last-child {
    width: 55px;
    display: inline-block;
}

.goals-priority-list-container .ant-list-item-meta-title {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
}

.goals-priority-list-container .ant-list-item-meta-title .parent-path-view {
    font-size: 15px;
}

.goals-priority-list-container .ant-list-item-meta-title > div {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    justify-content: flex-start;
}

.goals-priority-list-container .ant-list-item-meta-title > div > span {
    flex: 1 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.name-wrapper {
    max-width: 250px;
}

.anticon.colorful {
    color: grey;
}

.anticon.colorful.level1 {
    color: #008a00;
}

.anticon.colorful.level3 {
    color: #faad14;
}

.anticon.colorful.level7 {
    color: red;
}

.item-path span {
    font-size: 10px;
    padding: 3px 3px;
    line-height: 9px;
}

@media only screen and (max-width: 450px) {
    .goals-priority-list-container .ant-list-item-meta-title > div > span {
        white-space: pre-wrap;
    }
}
