.done-item-name {
    color: grey;
    display: flex;
    gap: 5px;
    flex-flow: row nowrap;
    align-items: center;
}

.done-item-name > span {
    flex: 0 0 auto;
}

.done-item-name > span.long-name {
    flex: 1 1 auto;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.done-item-name > i {
    display: none;
    margin-right: 5px;
}

.done-item-name > i.anticon:before {
    display: inline-block;
}

.done-item-name .ant-checkbox-wrapper {
    margin-right: 5px;
}

.done-item-name.done {
    color: black;
}

.done-item-name.done > i.done-icon {
    display: inherit;
}

.done-item-name > span.long-name {
    display: inline-block;
    white-space: pre;
}
