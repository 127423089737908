.results-note {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
}

.results-note .title {
    margin-bottom: 5px;
}
