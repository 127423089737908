.results.page button.change-area {
    display: none;
}

.results-container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-flow: column nowrap;
}

.results-container .search-bar {
    width: 100%;
    flex: 0 0 32px;
}

.results-container .search-results {
    width: 100%;
    flex: 1 1 auto;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow: auto;
}

.results-container .search-results .summary-details {
    flex: 1 1 auto;
    overflow: hidden;
}

.results-container .search-results .period-achievements {
    flex: 0 0 320px;
    margin-left: 10px;
    overflow-x: hidden;
    overflow-y: auto;
}

.results-container .search-results .blocks-delimiter {
    flex: 0 0 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: none;
}

.results .page-list,
.results .page-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.results > .page-list > .header,
.results > .page-list > .footer {
    width: 100%;
    overflow: hidden;
    flex: 0 0 50px;
}

.results > .page-list > .header {
    flex: 0 0 32px;
}

.results > .page-list > .footer {
    margin-top: 10px;
    display: none;
}

.results > .page-list > .content {
    width: 100%;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 8px;
}

.results > .page-list .results-notes-list {
    background-color: white;
}

@media only screen and (max-width: 1100px) {
    .results-container .search-results {
        flex-flow: column nowrap;
        overflow: hidden;
    }

    .results-container .search-results.summary .summary-details,
    .results-container .search-results.done-items .period-achievements,
    .results-container .search-results.both .summary-details,
    .results-container .search-results.both .period-achievements{
        flex: 1 1 auto;
        min-height: 45%;
        margin-left: 0;
    }

    .results-container .search-results.summary .period-achievements,
    .results-container .search-results.done-items .summary-details {
        display: none;
    }

    .results-container .search-results.summary .blocks-delimiter button.more-results,
    .results-container .search-results.done-items .blocks-delimiter button.more-achievements {
        display: none;
    }

    .results-container .search-results.both .blocks-delimiter button.more-results {
        transform: rotate(180deg);
    }

    .results-container .search-results .blocks-delimiter {
        display: flex;
    }
}

@media only screen and (max-width: 700px) {
    .results.page > .page-list, .results.page > .page-content {
        flex: 1 1 auto;
        margin-left: 0;
    }

    .results.page.done-items-hierarchy > .page-list, .results.page.summary-list > .page-content {
        display: none;
    }

    .results.page button.change-area {
        display: block;
    }

    .results > .page-list > .footer {
        display: initial;
    }
}

@media only screen and (max-width: 450px) {
    .results > .page-list > .footer {
        display: none;
    }
}
