.note-timer .running-only {
    display: none;
}

.note-timer.running .running-only {
    display: inherit;
}

.note-timer.running .stopped-only {
    display: none;
}

.note-timer-config .ant-input-number{
    width: 56px;
    margin-right: 10px;
}

.note-timer .count-block .stop-btn {
    display: none;
}

.note-timer .count-block:hover .stop-btn {
    display: inherit;
}

.note-timer .count-block:hover .ant-progress {
    display: none;
}