.goals.page button.change-area {
    display: none;
}

.goals .page-list,
.goals .page-content {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.goals > div > .header,
.goals > div > .footer {
    width: 100%;
    overflow: hidden;
    flex: 0 0 50px;
}

.goals > div > .header {
    flex: 0 0 32px;
}

.goals > div > .footer {
    margin-top: 10px;
}

.goals > div.page-content > .footer {
    display: none;
}

.goals > div > .content {
    width: 100%;
    flex: 1 1 auto;
    overflow-x: hidden;
    margin-top: 8px;
}

.goals .page-list .priority-list-filter {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .goals.page > .page-list,
    .goals.page > .page-content {
        flex: 1 1 auto;
        margin-left: 0;
    }

    .goals.page.hierarchy > .page-list,
    .goals.page.priority-list > .page-content {
        display: none;
    }

    .goals.page button.change-area {
        display: block;
    }

    .goals > div.page-content > .footer {
        display: block;
    }
}

@media only screen and (max-width: 450px) {
    .goals.page > div > .footer {
        display: none;
    }
}
