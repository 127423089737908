.landing {
    height: 100%;
    overflow-y: scroll;
}

.page-block {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.page-block.vv {
    flex-direction: row-reverse;
}

.page-block .text-part {
    background-color:rgba(255, 255, 255, 0.8);
    margin: 20px;
}

.page-block .text-part .text-container {
    margin: 10% 10%;
}

.page-block .text-part .text-container .header1 {
    font-size: 50px;
    color: coral;
    margin-bottom: 0;
}

.page-block .text-part .text-container .header2 {
    font-size: 45px;
    color: grey;
}

.page-block .text-part .text-container .text {

}

.page-block .text-part .text-container .points {
    margin-top: 10px;
}

.page-block .text-part .text-container .actions {
    margin-top: 20px;
}

.page-block .text-part .text-container .actions .action {
    width: fit-content;
    background-color: #e44232;
    color: #fff;
    border-color: #e44232;
    border-radius: 2px;
    cursor: pointer;

    font-size: 1.125rem;
    line-height: 1.95rem;
    min-height: 4rem;
    padding: 1rem 1.5rem;

    display: block;
}
.page-block .text-part .text-container .actions .action:hover {
    background-color: #e44272;
    border-color: #e44272;
}

.page-block .image-part {
    height: 100%;
    width: 40%;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-block .image-part {
        display: none;
    }

    .page-block .text-part .text-container .header1 {
        font-size: 25px;
    }

    .page-block .text-part .text-container .header2 {
        font-size: 20px;
    }
}
