.goals-priority-list-container .goals-priority-list-header {
    display: flex;
    justify-content: space-between;
}

.goals-priority-list-container .goals-priority-list-header .ant-select {
    width: 130px;
    margin-left: 5px;
}

.goals-priority-list-container.ant-list .group-header {
    background-color: grey;
    color: white;
    padding-left: 5px;
    font-size: 90%;
}

.goals-priority-list-container {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
}

.goals-priority-list-container .ant-layout-header {
    background-color: transparent !important;
    height: 49px;
    padding: 0;
    line-height: 0;
}
