.habits-table td.habit-name-col {
    padding: 0;
    height: 54px;
    text-align: left;
}

.habits-table th.habit-name-col {
    text-align: left;
    padding-left: 10px;
}

.habits-table td.habit-name-col {
    padding: 0;
    background: #fff;
}

.habit-name-renderer {
    height: 100%;
    min-width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.habit-name-renderer .name-container {
    flex: 1 1 auto;
    overflow: hidden;
    padding-left: 10px;
}

.habit-name-renderer .actions-container {
    flex: 0 0 54px;
    display: none;
    height: 54px;
    transition: all .3s;
    background: #e6f7ff;
}

.habits-table td.habit-name-col:hover .habit-name-renderer .actions-container {
    display: initial;
}

.habit-name-renderer .actions-container .action {
    width: 27px;
    height: 27px;
    display: inline-block;
    border-left: 1px solid #e8e8e8;
    text-align: center;
    line-height: 27px;
    cursor: pointer;
}

.habit-name-renderer .actions-container .action:hover {
    color: #1890ff;
}

@media only screen and (max-width: 700px) {
    .habit-name-renderer {
        min-width: 150px;
    }
}
