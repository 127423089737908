.habit-amend-periods {
    margin-top: 5px;
    width: 100%;
    display: flex;
}

.habit-amend-periods label {
    width: 25%;
    padding: 0;
    text-align: center;
}