.top-menu {
    display: flex;
    justify-content: space-between;
    height: 64px;
    overflow: hidden;
}

.top-menu .home-link {
    margin-left: 20px;
    font-size: 45px;
}

.top-menu .top-menu-container {
    display: flex;
}
