.habits-filter-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.habits-filter-header .habits-filter > div {
    margin-right: 10px;
}

.habits-filter-header .habits-filter {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.habits-filter-header .plus-button {
    flex: 0 0 50px;
    margin-left: 10px;
    display: none;
}

@media only screen and (max-width: 450px) {
    .habits-filter-header .plus-button {
        display: initial;
    }
}

@media only screen and (max-width: 400px) {
    .habits-filter-header > .habits-filter > div.ant-btn-group > button {
        display: none;
    }
}
