.habits-table {
    width: 100%;
    overflow-x: auto;
}

.habits-table thead th,
.habits-table tbody td {
    padding: 16px 0;
    text-align: center;
}

.habits-table th.current {
    background-color: antiquewhite;
}

.habits-table td.current {
    background-color: beige;
}

.habits-table th.weekend {
    background-color: #d5d4d4;
}

.habits-table td.weekend {
    background-color: #e8e8e8;
}

.habits-table td.weekend[colspan="7"],
.habits-table td.weekend[colspan="28"],
.habits-table td.weekend[colspan="29"],
.habits-table td.weekend[colspan="30"],
.habits-table td.weekend[colspan="31"] {
    background: inherit;
}
