.item-name {
    flex: 1 1 auto;
    color: gray;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.item-name.filter-fit {
    color: black;
}

.item-name.done {
    text-decoration: line-through;
}

.item-name > div {
    display: inline-block;
}

.item-name .name-container {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
}

.item-name .name-container > .checkbox {
    flex: 0 0 auto;
}

.item-name .name-container > .title {
    flex: 1 1 auto;
    text-overflow: ellipsis;
    overflow: hidden;
    /*white-space: nowrap;*/
}

.item-name .name-container > .holder {
    flex: 0 0 auto;
    cursor: grab;
}

.item-name .name-container .ant-list-item-meta-description {
    font-size: 13px;
}

.item-name .link-container {
    width: 30px;
    text-align: center;
}

.item-name .icon-details-container {
    flex: 0 0 120px;
    overflow: visible;
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.5;
    transition: all 0.3s;
}

.action-item-tr:hover .icon-details-container {
    display: flex;
}

.item-name .icon-details-container .actions {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
}

.item-name .icon-details-container .actions > div {
    display: flex;
    margin-right: 10px;
}

.item-name .icon-details-container .actions > div:last-child {
    margin-right: 0;
}

.item-name .icon-details-container .actions > div > div i {
    margin-right: 5px;
}

.item-name .icon-details-container .actions i.anticon.anticon-calendar.ant-calendar-picker-icon {
    font-size: 25px;
    margin-left: -12px;
    margin-top: -22px;
    cursor: pointer;
    color: grey;
}

.ant-table-tbody > tr:hover > td .item-name .icon-details-container {
    opacity: 1;
}

.item-name .icon-details-container > div.today {
    text-align: center;
    border: solid 1px green;
    border-radius: 2px;

    width: 15px;
    height: 15px;
    line-height: 13px;
    margin-top: 3px;
    font-size: 11px;
    color: green;
}

.item-name .icon-details-container > div:hover {
    --color: #1890ff;
}

@media only screen and (max-width: 450px) {
    .item-name .icon-details-container {
        display: none !important;
    }

    .item-name .name-container > .holder {
        display: none !important;
    }
}
