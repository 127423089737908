.habits {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.habits > .header,
.habits > .footer {
    width: 100%;
    overflow: hidden;
}

.habits > .header {
    flex: 0 0 32px;
}

.habits > .footer {
    flex: 0 0 55px;
}

.habits > .content {
    width: 100%;
    flex: 1 1 auto;
    overflow: auto;
}

.habits .content,
.habits .footer {
    margin-top: 10px;
}

.habits .add-item.desktop {
    width: 300px;
    margin-top: 10px;
}

.habits .mobile {
    display: none;
}

@media only screen and (max-width: 700px) {
    .habits .mobile {
        display: initial;
    }

    .habits .desktop {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .habits .footer {
        display: none;
    }
}
