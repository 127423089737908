.ant-drawer.mobile-menu .ant-drawer-body {
    padding: 0;
}

.ant-drawer.mobile-menu .ant-drawer-header {
    padding-left: 10px;
    padding-right: 10px;
}

.ant-drawer.mobile-menu .ant-drawer-header .ant-drawer-title {
    text-align: center;
}

.ant-drawer.mobile-menu .ant-drawer-header .ant-drawer-close {
    margin-right: 0;
}

.ant-drawer.mobile-menu .ant-drawer-header-title {
    flex-direction: row-reverse;
}

.items-container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.items-container .item {
    width: 100%;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    color: black;
}

.items-container .item .title {
    font-size: 15px;
}

.items-container .item .title .anticon {
    padding-right: 10px;
}
