.period-items > div:first-child {
    margin-top: 0;
}

.period-items > div {
    margin-top: 15px;
}

.period-items .ant-table.ant-table-small {
    border: none;
}

.period-items table {
    table-layout: fixed !important;
}
