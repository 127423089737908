.results-note-list-item {
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    padding-left: 12px;
    padding-right: 12px;
}

.results-note-list-item h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.results-note-list-item:hover {
    background-color: rgba(128, 214, 255, 0.2);
}

.results-note-list-item.selected {
    background-color: #80d6ff;
}

.results-note-list-item .actions {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
}
