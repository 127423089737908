.goals-hierarchy-actions {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.goals-hierarchy-actions .actions {
    flex: 0 0 135px;
}

.goals-hierarchy-actions .filters {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.goals-hierarchy-actions .filters .search {
    flex: 1 1 auto;
}

.goals-hierarchy-actions .filters .filter {
    flex: 0 0 35px;
}

.goals-hierarchy-actions .plus-button {
    display: none;
    margin-left: 5px;
}

@media only screen and (max-width: 450px) {
    .goals-hierarchy-actions .filters,
    .goals-hierarchy-actions.noItemsSelected .actions {
        display: none;
    }

    .goals-hierarchy-actions .actions,
    .goals-hierarchy-actions.noItemsSelected .filters {
        display: flex;;
    }

    .goals-hierarchy-actions .plus-button {
        display: initial;
    }
}
