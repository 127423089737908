.results-list-filter {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.results-list-filter .period-select {
    flex: 0 0 95px;
}

.results-list-filter .name-filter {
    margin-left: 5px;
    flex: 1 1 auto;
}

.results-list-filter .plus-button {
    flex: 0 0 50px;
    margin-left: 5px;
    display: none;
}

@media only screen and (max-width: 450px) {
    .results-list-filter .plus-button {
        display: initial;
    }
}
