.item-properties .properties-group {
    margin-top: 15px;
}

.item-properties .properties-group:first-child {
    margin-top: 0px;
}

.item-properties .properties-group > div,
.item-properties .properties-group > input {
    margin-top: 5px;
}

.item-properties span.label {
    color: grey;
}

.item-properties .item-date {
    display: block;
}
