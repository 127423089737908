#eddy,
#eddy > .main-layout {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-layout {
    display: flex;
    flex-flow: column nowrap;
}

.main-layout > .main-header {
    flex: 0 0 64px;
    background-color: #001529;
}

.main-layout > .main-content {
    flex: 1 1 auto;
    overflow: hidden;
}

.clrfix:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}
.padding0 {
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

:focus {
    outline: none;
}

.jc-c {
    justify-content: center;
}

.jc-sb {
    justify-content: space-between;
}

/*triple selection*/
.tripple-selection {
    width: fit-content;
    display: inline-block;
    height: 24px;
}

.tripple-selection .background-text {
    text-align: center;
    margin-top: 0;
}

.tripple-selection .ant-radio-group {
    width: 100%;
}

.tripple-selection .ant-radio-group > label {
    opacity: 0.3;
    border: solid 1px #faad14;
    width: 33%;
}

.tripple-selection .ant-radio-group > label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    opacity: 0.3;
    background-color: #faad14;
}

.tripple-selection .ant-radio-group > label:first-child {
    border-color: #008a00;
    border-right: none;
}

.tripple-selection .ant-radio-group > label:last-child {
    border-color: red;
    border-left: none;
}

.tripple-selection .ant-radio-group,
.tripple-selection .ant-radio-group .ant-radio-button-wrapper {
    height: 15px;
}

.tripple-selection .ant-radio-group > label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:first-child {
    background-color: #008a00;
}

.tripple-selection .ant-radio-group > label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:last-child {
    background-color: red;
}

.quill {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}

.quill > .ql-toolbar {
    flex: 0 0 42px;
}

.quill > .ql-container {
    flex: 1 1 auto;
    overflow-y: auto;
}

.quill > .ql-container {
    background-color: white;
}

.ant-space-align-center {
    justify-content: center;
}

.mobile-only {
    display: none;
}

/*flex container*/
.flex-container {
    display: flex;
}

.flex-container.row {
    flex-flow: row nowrap;
}

.flex-container.column {
    flex-flow: column nowrap;
}

.flex-container.gap5 {
    gap: 5px;
}

@media only screen and (max-width: 700px) {
    .quill .ql-toolbar.ql-snow .ql-formats {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .ant-popover {
        display: none;
    }
    .desktop-only {
        display: none !important;
    }
    .mobile-only {
        display: initial;
    }
}
